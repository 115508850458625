// vendors
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

// utils
import breakpointsRange from '../utils/breakpointsRange';
import {
  greaterThan,
  lessThan,
  greaterThanCondition,
} from '../utils/mediaQuery';

// styles
import { introStyle, withFrontUnderline, h2Style } from '../styles/global';
import breakpoints from '../styles/breakpoints';
import colors from '../styles/colors';

// images
import StampSpecialists from '../images/StampSpecialists';
import vectorExperience from '../images/vectorExperience.svg';

// components
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroWithTitle from '../components/HeroWithTitle';
import Button from '../components/Button';
import WithSidebar from '../components/WithSidebar';
import AskQuestionsForm from '../components/AskQuestionsForm';

const ctaBreakpoint = 1280;

const IntroSection = styled.section`
  max-width: 1440px;
  margin: auto;

  text-align: center;

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [40, 120] },
      { prop: 'marginBottom', sizes: [68, 140] },
      { prop: 'paddingLeft', sizes: [28, 40, undefined, 140] },
      { prop: 'paddingRight', sizes: [28, 40, undefined, 140] },
    ],
    breakpoints.spacings
  )};
`;

const TitleWrapper = styled.div`
  display: flex;

  text-transform: uppercase;

  h1 {
    flex-basis: 0;

    margin: 0;

    line-height: ${35 / 30};

    word-break: initial;

    ${breakpointsRange(
      [{ prop: 'fontSize', sizes: [30, 56] }],
      breakpoints.fonts
    )};
  }
`;

const DetailSection = styled.section`
  background-color: ${colors.veryLightPink};

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [68, 140] },
      { prop: 'marginBottom', sizes: [68, 140] },
    ],
    breakpoints.spacings
  )};
`;

const DetailContainer = styled(WithSidebar)`
  max-width: 1440px;
  margin: auto;

  ${breakpointsRange(
    [
      { prop: 'paddingTop', sizes: [68, 134] },
      { prop: 'paddingBottom', sizes: [88, 140] },
      { prop: 'paddingRight', sizes: [28, 40, 40, 120] },
      { prop: 'paddingLeft', sizes: [28, 40, 40, 120] },
    ],
    breakpoints.spacings
  )};
`;

const DetailIcon = styled.img`
  min-width: 260px;
`;

const DetailTextWrapper = styled.div`
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

const DetailButtonWrapper = styled.div`
  display: grid;
  grid-gap: 1em 2em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  ${breakpointsRange(
    [{ prop: 'marginTop', sizes: [48, 68] }],
    breakpoints.fonts
  )};
`;

const DetailButton = styled(Button)``;

const Stamp = styled(StampSpecialists)`
  color: ${colors.azure};
`;

const CTASection = styled.section`
  max-width: 1560px;
  margin: auto;

  ${breakpointsRange(
    [
      { prop: 'marginTop', sizes: [88, 168] },
      { prop: 'marginBottom', sizes: [88, 168] },
    ],
    breakpoints.spacings
  )}
`;

const CTAList = styled.div`
  display: grid;
  grid-row-gap: 96px;
  grid-template-columns: 1fr;
  justify-content: space-between;

  max-width: 375px;
  margin: auto;

  ${greaterThan(640)} {
    max-width: 640px;
  }

  ${greaterThan(768)} {
    grid-column-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    max-width: initial;
  }

  ${greaterThan(832)} {
    grid-column-gap: 80px;
  }

  ${greaterThan(992)} {
    grid-column-gap: 96px;
  }

  ${greaterThan(1280)} {
    grid-column-gap: 120px;
  }
`;

const CTACard = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  ::before {
    display: block;
    margin-bottom: ${(288 / 320) * 100}%;

    content: '';

    ${greaterThan(ctaBreakpoint)} {
      margin-bottom: ${(480 / 720) * 100}%;
    }
  }
`;

const CTAContentWrapper = styled.div`
  z-index: 1;

  flex-grow: 0;

  p {
    margin-top: 0;

    line-height: ${48 / 38};
  }

  max-width: 172px;

  margin-top: 48px;
  margin-right: 28px;

  ${greaterThan(640)} {
    max-width: 200px;
  }

  ${greaterThan(832)} {
    max-width: 200px;
  }

  ${greaterThan(992)} {
    flex-basis: 0;
    max-width: initial;
    margin-top: 64px;
    margin-right: 48px;
  }

  ${greaterThan(1280)} {
    margin-top: 64px;
    margin-right: 48px;
  }

  ${greaterThan(1440)} {
    min-width: 280px;
    margin-top: 64px;
    margin-right: 48px;
  }

  ${breakpointsRange([{ prop: 'fontSize', sizes: [20, 38] }], [640, 1440])}
`;

const teamCTACardStyles = css`
  color: ${colors.white};

  background-color: #3b79b2;
`;

const formationCTACardStyles = css`
  background-color: #7ad8fe;
`;

const ctaButtonStyles = css`
  ${lessThan(992)} {
    width: 100%;
    min-width: initial;
    padding-right: 0;
    padding-left: 0;
  }
`;

const CTAImgWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const EspaceProfessionnelPage = ({ data }) => {
  const [openAskQuestionsForm, setOpenAskQuestionsForm] = useState(false);

  const {
    headerImageMobile: {
      childImageSharp: { fluid: headerImageMobile },
    },
    headerImageDesktop: {
      childImageSharp: { fluid: headerImageDesktop },
    },
    ctaTeamSmall: {
      childImageSharp: { fluid: ctaTeamSmall },
    },
    ctaFormationSmall: {
      childImageSharp: { fluid: ctaFormationSmall },
    },
    ctaTeamLarge: {
      childImageSharp: { fluid: ctaTeamLarge },
    },
    ctaFormationLarge: {
      childImageSharp: { fluid: ctaFormationLarge },
    },
  } = data;

  const sizesSmall = `
  (min-width: 375px) 375px,
  (min-width: 640px) 650px,
  (min-width: 768px) ${(364 / 768) * 100}vw,
  (min-width: 832px) ${((832 - 80) / 2 / 832) * 100}vw,
  (min-width: 992px) ${((992 - 96) / 2 / 992) * 100}vw,
  (min-width: 1280px) ${((992 - 96) / 2 / 992) * 100}vw,
  100vw`;

  const sizesLarge = `
  (min-width: 1280px) ${((1280 - 96) / 2 / 1280) * 100}vw,
  (min-width: 1560px) 720px,
  100vw`;

  const sourceCTATeam = [
    { ...ctaTeamSmall, sizes: sizesSmall },
    {
      ...ctaTeamLarge,
      media: `(${greaterThanCondition(ctaBreakpoint)})`,
      sizes: sizesLarge,
    },
  ];

  const sourceCTAFormation = [
    { ...ctaFormationSmall },
    { ...ctaFormationLarge, media: `(${greaterThanCondition(ctaBreakpoint)})` },
  ];

  const handleOpenModal = () => setOpenAskQuestionsForm(true);

  const handleCloseModal = () => setOpenAskQuestionsForm(false);

  return (
    <>
      <Layout hasNoHeaderSpacer>
        <SEO
          title=' Espace Professionnel'
          description='Vous souhaitez référer l’un de vos patients à un spécialistes des cliniques Maxillo Québec Lebourgneuf et Lévis, voici votre espace professionnel.'
        />

        <HeroWithTitle
          pictureSmall={headerImageMobile}
          pictureLarge={headerImageDesktop}
          renderStamp={<Stamp />}
          backgroundColor={colors.veryLightPink}
        >
          <TitleWrapper>
            <h1>Espace professionnel</h1>
          </TitleWrapper>
        </HeroWithTitle>

        <IntroSection>
          <p css={introStyle}>
            Depuis plus de 20 ans, l’équipe de Maxillo Québec est le centre de
            référence dans le domaine la chirurgie buccale et maxillo-faciale de
            l’est du Québec. De l’intervention la plus simple à la plus
            complexe, les chirurgiens spécialistes de la clinique Maxillo Québec
            sont la référence pour collaborer promptement avec vous et vos
            patients.
          </p>
        </IntroSection>

        <DetailSection>
          <DetailContainer
            align='center'
            contentMin='54%'
            spaceVertical={[56, 104]}
            spaceHorizontal={[56, 104]}
            renderSidebar={<DetailIcon src={vectorExperience} />}
          >
            <DetailTextWrapper>
              <h2 css={[h2Style, withFrontUnderline]}>
                Une expérience optimale
              </h2>

              <p>
                Nous accordons une attention marquée et soutenue à bien vous
                répondre, à partager et à traiter vos patients avec la meilleure
                coordination de traitements. Chez Maxillo Québec, nous assurons
                une expérience de soins optimale à chacun de nos référents et
                des patients qui nous sollicitent et nous honorent de leur
                confiance.
              </p>

              <DetailButtonWrapper>
                <DetailButton to='/referer-un-patient'>
                  Référer un patient chez&nbsp;nous
                </DetailButton>

                <DetailButton outlined onClick={handleOpenModal}>
                  Poser une question à un&nbsp;chirurgien
                </DetailButton>
              </DetailButtonWrapper>
            </DetailTextWrapper>
          </DetailContainer>
        </DetailSection>

        <CTASection>
          <CTAList>
            <CTACard css={[teamCTACardStyles]}>
              <CTAImgWrapper>
                <Img fluid={sourceCTATeam} role='presentation' />
              </CTAImgWrapper>

              <CTAContentWrapper>
                <p>Notre équipe, la référence dans le domaine.</p>

                <Button to='/equipe' white outlined css={[ctaButtonStyles]}>
                  Découvrez l’équipe
                </Button>
              </CTAContentWrapper>
            </CTACard>

            <CTACard css={[formationCTACardStyles]}>
              <CTAImgWrapper>
                <Img fluid={sourceCTAFormation} role='presentation' />
              </CTAImgWrapper>

              <CTAContentWrapper>
                <p>Des formations à la hauteur de vos attentes.</p>

                <Button to='/formations' outlined css={[ctaButtonStyles]}>
                  Voir les formations
                </Button>
              </CTAContentWrapper>
            </CTACard>
          </CTAList>
        </CTASection>
      </Layout>

      <AskQuestionsForm
        isOpen={openAskQuestionsForm}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default EspaceProfessionnelPage;

EspaceProfessionnelPage.propTypes = {
  data: PropTypes.shape({
    headerImageDesktop: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    headerImageMobile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    ctaTeamLarge: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    ctaTeamSmall: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    ctaFormationLarge: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
    ctaFormationSmall: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          srcSetWebp: PropTypes.string.isRequired,
        }),
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    headerImageDesktop: file(name: { eq: "img-header-EspacePro-2520x2160" }) {
      ...HeroWithTitleLargePictureData
    }

    headerImageMobile: file(name: { eq: "img-header-EspacePro-2220x2766" }) {
      ...HeroWithTitleSmallPictureData
    }

    ctaTeamLarge: file(name: { eq: "img-cta-pro-page-team-large" }) {
      childImageSharp {
        fluid(maxWidth: 720, maxHeight: 480, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ctaTeamSmall: file(name: { eq: "img-cta-pro-page-team-small" }) {
      childImageSharp {
        fluid(maxWidth: 720, maxHeight: 648, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ctaFormationLarge: file(name: { eq: "img-cta-pro-page-formation-large" }) {
      childImageSharp {
        fluid(maxWidth: 720, maxHeight: 480, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    ctaFormationSmall: file(name: { eq: "img-cta-pro-page-formation-small" }) {
      childImageSharp {
        fluid(maxWidth: 720, maxHeight: 648, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
