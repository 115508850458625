// vendors
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
`;

const RoundContainer = styled.g`
  transform-origin: center;

  animation: ${rotate} 120s linear infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

const StampSpecialists = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
    <RoundContainer>
      <path d='M5.3 70.9l1.9-5.6a7.8 7.8 0 011.4-2.5 5.7 5.7 0 012.1-1.6 7 7 0 013-.5 11.9 11.9 0 013.5.7 12.1 12.1 0 013.3 1.6 7 7 0 012 2.2 5.8 5.8 0 01.7 2.5 7.6 7.6 0 01-.4 3l-1.9 5.5zm15.2-1a4 4 0 00-.1-3.2 4.7 4.7 0 00-2.9-2.2l-2.5-.8a4.7 4.7 0 00-3.6 0 4 4 0 00-2 2.4l-1 3 11.1 3.8z' />
      <path d='M29.9 56.2a6 6 0 01-1.6 1.8 5 5 0 01-2 .9 5.7 5.7 0 01-2.3 0 8.4 8.4 0 01-2.5-1 8.3 8.3 0 01-2.1-1.7 5.7 5.7 0 01-1.2-2 4.8 4.8 0 01-.3-2.1 5.8 5.8 0 01.8-2.3 5.7 5.7 0 011.6-1.8 4.8 4.8 0 012-.8 5.7 5.7 0 012.2 0 8.2 8.2 0 012.2 1l.9.4-4.2 7.3.3.2a3.2 3.2 0 002.5.5 3.1 3.1 0 002-1.7 3.5 3.5 0 00.5-1.9 4.5 4.5 0 00-.5-1.8l2-.5a4.8 4.8 0 01.6 2.6 6.5 6.5 0 01-1 3zm-9.4-5.4A3.1 3.1 0 0020 52a2.8 2.8 0 00.1 1.1 2.8 2.8 0 00.6 1 3.9 3.9 0 001 .9h.1l2.9-5h-.2a3.4 3.4 0 00-2.3-.5 2.5 2.5 0 00-1.8 1.3z' />
      <path d='M34.9 48.1l-9.3-8 1.7-2 1.5 1.4v-.1a4 4 0 01-.4-2 3.4 3.4 0 011-2.1 3.6 3.6 0 012.9-1.4 5.3 5.3 0 013.3 1.5l6 5-1.8 2-5.6-4.8c-1.4-1.3-2.7-1.3-3.7-.2a3.5 3.5 0 00-.5.8 2.6 2.6 0 00-.3 1 2 2 0 00.1.8 1.9 1.9 0 00.6.8l6.2 5.4z' />
      <path d='M47.6 35.1a2.4 2.4 0 01-3.8-.2L39 29.2l-1.4 1.3-1.4-1.6.8-.7a1.1 1.1 0 00.5-.8 1.4 1.4 0 00-.4-.9L35.6 25l1.7-1.5 2.2 2.5 2-1.6 1.3 1.5-2 1.7 5.3 6.1 1.9-1.5 1.4 1.6z' />
      <path d='M46 18.3a1.6 1.6 0 01-1.2.3 1.3 1.3 0 01-.8-.6l-.2-.3a1.4 1.4 0 01-.2-1 1.6 1.6 0 01.7-1 1.5 1.5 0 011.2-.3 1.4 1.4 0 01.9.6l.2.4a1.4 1.4 0 01.2 1 1.5 1.5 0 01-.8 1zm0 2.6l2.3-1.4 6.6 10.3-2.2 1.4z' />
      <path d='M62.6 25.7a6.4 6.4 0 01-2.9.8 6.5 6.5 0 01-2.6-.6l.8-2a5.4 5.4 0 002 .4 4 4 0 001.9-.5 2.6 2.6 0 001.3-1.2 1.4 1.4 0 00-.1-1.3 1.4 1.4 0 00-.8-.7 2.2 2.2 0 00-1.4.1l-1.1.4a5.8 5.8 0 01-3.2.4 3 3 0 01-2-1.8 3.6 3.6 0 01-.5-1.6 3.3 3.3 0 01.3-1.4 4.2 4.2 0 011-1.3 6 6 0 011.5-1.1 5.6 5.6 0 015-.4L61 16a4.4 4.4 0 00-1.6-.3 3.7 3.7 0 00-1.7.4 2.4 2.4 0 00-1.2 1.1 1.4 1.4 0 000 1.2 1.3 1.3 0 00.9.8A3.2 3.2 0 0059 19l1-.4a5.5 5.5 0 013.3-.3 3.1 3.1 0 011.9 1.7 3.5 3.5 0 010 3.1 4.5 4.5 0 01-1 1.5 6.8 6.8 0 01-1.6 1.1z' />
      <path d='M73.8 20.8a2.4 2.4 0 01-3.5-1.6L68 12l-1.8.6-.7-2 1-.4a1.1 1.1 0 00.8-.5 1.5 1.5 0 000-1l-.8-2 2.2-.7 1 3.2 2.5-.9.7 2-2.4.9 2.5 7.6 2.3-.8.7 2z' />
      <path d='M84.6 18.4a6 6 0 01-2.4 0 4.9 4.9 0 01-2-1 5.9 5.9 0 01-1.5-1.7A8.9 8.9 0 0178 13a8.7 8.7 0 01-.1-2.7 6 6 0 01.7-2.2 4.9 4.9 0 011.6-1.5 6 6 0 012.2-1 5.7 5.7 0 012.4 0 4.8 4.8 0 012 1A5.6 5.6 0 0188 8.6a8 8 0 01.8 2.3l.1 1-8.3 1.5.1.4a3.3 3.3 0 001.2 2 3.1 3.1 0 002.6.5 3.5 3.5 0 001.8-.8 4.2 4.2 0 001-1.5l1.8 1.2a4.9 4.9 0 01-1.7 2.1 6.6 6.6 0 01-2.8 1.2zm-2-10.7a3 3 0 00-1 .5 2.6 2.6 0 00-.9.8 3 3 0 00-.4 1.1 4 4 0 000 1.3v.2l5.6-1v-.2a3.4 3.4 0 00-1-2.2 2.5 2.5 0 00-2.2-.5z' />
      <path d='M97.9 17.2a6.3 6.3 0 01-3-.6 6.5 6.5 0 01-2-1.6l1.5-1.6a5.4 5.4 0 001.6 1.3 4 4 0 002 .4 2.7 2.7 0 001.7-.5 1.4 1.4 0 00.5-1.2 1.4 1.4 0 00-.4-1 2.2 2.2 0 00-1.4-.5l-1.1-.1a5.7 5.7 0 01-3-1A3.1 3.1 0 0193 8.2a3.8 3.8 0 01.3-1.6 3.2 3.2 0 011-1.2 4.3 4.3 0 011.4-.7 7 7 0 014.5 0 6.4 6.4 0 012 1.5l-1.6 1.5a4 4 0 00-1.3-1 3.6 3.6 0 00-1.7-.3 2.4 2.4 0 00-1.6.4 1.4 1.4 0 00-.5 1.2 1.3 1.3 0 00.4 1 3.1 3.1 0 001.5.5h1.1a5.5 5.5 0 013 1.2 3.1 3.1 0 011 2.4 3.8 3.8 0 01-.3 1.6 3.3 3.3 0 01-1 1.3 4.7 4.7 0 01-1.4.8 6.9 6.9 0 01-2 .4z' />
      <path d='M108.6 14.2a1.8 1.8 0 011.3.6 1.6 1.6 0 01.2 1.2v.4a5.3 5.3 0 01-.9 2.3 6.3 6.3 0 01-2 1.9l-1.9-.2a11.5 11.5 0 001.5-1.3 5.3 5.3 0 001-1.5 1.4 1.4 0 01-.9-.6 1.7 1.7 0 01-.2-1v-.4a1.6 1.6 0 01.6-1.1 1.7 1.7 0 011.3-.3z' />
      <path d='M121.3 19.7l4.5-11.4 2.4 1-.8 1.8h.1a5.4 5.4 0 01.8-.6 3.2 3.2 0 01.9-.5 3 3 0 011-.1 4 4 0 011.2.3 4.1 4.1 0 011.8 1.4 3.5 3.5 0 01.7 2.3 3.6 3.6 0 011.9-1.2 3.9 3.9 0 012.5.2 3.5 3.5 0 012.2 2.2 5.5 5.5 0 01-.4 3.6l-2.8 7.3-2.4-1 2.7-7a3.5 3.5 0 00.4-2 2 2 0 00-1.3-1.3 3.5 3.5 0 00-1-.2 2.5 2.5 0 00-.8 0 2 2 0 00-1.3 1.2l-3 7.7-2.4-1 2.7-7c.7-1.7.4-2.9-1-3.4a3.5 3.5 0 00-1-.2 2.5 2.5 0 00-.8 0 2 2 0 00-.8.4 2 2 0 00-.5.8l-3 7.7z' />
      <path d='M145 31.9a5.9 5.9 0 01-1.7-1.7 5 5 0 01-.7-2.1 5.8 5.8 0 01.3-2.3 8 8 0 011.2-2.4 8.5 8.5 0 011.9-2 5.8 5.8 0 012-1 4.9 4.9 0 012.3 0 5.8 5.8 0 012.2 1 5.6 5.6 0 011.6 1.8 4.7 4.7 0 01.7 2 5.8 5.8 0 01-.3 2.3 8 8 0 01-1.1 2l-.6.9-6.8-5-.2.4a3.3 3.3 0 00-.7 2.4 3.1 3.1 0 001.4 2 3.5 3.5 0 001.9.8 4.5 4.5 0 001.9-.3l.3 2a5 5 0 01-2.7.4 6.5 6.5 0 01-2.9-1.2zm6.3-8.8a3 3 0 00-1.2-.6 2.8 2.8 0 00-1.1 0 3.1 3.1 0 00-1 .5 3.8 3.8 0 00-1 1l-.1.1 4.7 3.3.1-.1a3.5 3.5 0 00.7-2.3 2.5 2.5 0 00-1-2zm2-2.5l-.8-1.5 4.2-2.3L158 19z' />
      <path d='M159.6 41.6h-.1a3.5 3.5 0 01-2.2.2 3.7 3.7 0 01-1.9-1.2 4.3 4.3 0 01-1.2-3.7 7.7 7.7 0 012.5-4.2 7.7 7.7 0 014.3-2.4 4.3 4.3 0 013.7 1.5 3.8 3.8 0 011 2 3.5 3.5 0 01-.3 2.1l5.3-5 1.8 2-12.6 12L158 43zm-2-2a3.4 3.4 0 001.8 1 1.8 1.8 0 001.6-.4l3-2.9a1.8 1.8 0 00.6-1.6 3.3 3.3 0 00-1-1.9 2.8 2.8 0 00-2.1-1 3.1 3.1 0 00-2.3 1l-1.5 1.5a3.3 3.3 0 00-1 2.2 2.8 2.8 0 00.9 2.2z' />
      <path d='M165.4 52.8a5.8 5.8 0 01-.9-2.2 5 5 0 01.2-2.2 5.8 5.8 0 011-2 8.6 8.6 0 012-1.8 8 8 0 012.5-1 6 6 0 012.3-.2 4.9 4.9 0 012 .8 5.8 5.8 0 011.7 1.7 5.5 5.5 0 01.9 2.3 4.7 4.7 0 01-.2 2.1 5.6 5.6 0 01-1.1 2 8 8 0 01-1.8 1.5l-.8.5-4.6-7.1-.3.2a3.3 3.3 0 00-1.5 2 3.1 3.1 0 00.5 2.5 3.5 3.5 0 001.5 1.4 4.3 4.3 0 001.8.4l-.4 2a5 5 0 01-2.6-.7 6.5 6.5 0 01-2.2-2.2zm9.2-5.8a3.2 3.2 0 00-.9-.9 2.7 2.7 0 00-1-.4 2.8 2.8 0 00-1.2 0 3.6 3.6 0 00-1.3.5h-.1l3 4.9.3-.1a3.4 3.4 0 001.5-1.9 2.5 2.5 0 00-.3-2.1z' />
      <path d='M172.2 65.3a6 6 0 01-.5-2.4 4.8 4.8 0 01.4-2.1 5.9 5.9 0 011.4-1.8 8.6 8.6 0 012.3-1.4 9 9 0 012.6-.8 5.8 5.8 0 012.3.2 4.7 4.7 0 011.9 1 5.9 5.9 0 011.3 2 5.2 5.2 0 01.5 3.1 4.8 4.8 0 01-1.3 2.5l-1.7-1.6a2.5 2.5 0 00.6-3.2 2.6 2.6 0 00-1.7-1.5 3.5 3.5 0 00-2.4.3l-2 .8a3.5 3.5 0 00-1.8 1.6 2.6 2.6 0 000 2.4 2.8 2.8 0 001.3 1.4 3.8 3.8 0 001.8.4l-.1 2.2a4.8 4.8 0 01-2.9-.8 5.3 5.3 0 01-2-2.3z' />
      <path d='M187.3 69.7l.7 2.5-11.8 3.3-.7-2.4zm2.5.7a1.6 1.6 0 010-1.3 1.4 1.4 0 01.8-.6l.4-.1a1.4 1.4 0 011 .1 2 2 0 01.6 2.2 1.4 1.4 0 01-.8.6l-.4.1a1.4 1.4 0 01-1 0 1.5 1.5 0 01-.6-1z' />
      <path d='M177.7 80.2l12.2-1.5.3 2.5-2 .3v.1a4 4 0 011.8 1 3.5 3.5 0 011 2.1 3.6 3.6 0 01-1 3.1 5.3 5.3 0 01-3.3 1.5l-7.8 1-.3-2.6 7.4-.9c2-.3 2.8-1.1 2.6-2.7a3.6 3.6 0 00-.3-1 3.2 3.2 0 00-.4-.8 2 2 0 00-1.7-.6l-8.2 1z' />
      <path d='M179 99a6.3 6.3 0 01.6-3 6.6 6.6 0 011.7-2l1.5 1.7a5.6 5.6 0 00-1.3 1.5 4 4 0 00-.5 1.9 2.6 2.6 0 00.4 1.7 1.5 1.5 0 001.2.6 1.4 1.4 0 001-.4 2.2 2.2 0 00.6-1.3l.1-1.2a5.8 5.8 0 011.2-2.9 3 3 0 012.6-1 3.6 3.6 0 011.6.4 3.3 3.3 0 011.1 1 4.2 4.2 0 01.7 1.5 7.2 7.2 0 01-.3 4.5 6.2 6.2 0 01-1.5 1.8l-1.5-1.6a4.1 4.1 0 001-1.2 3.7 3.7 0 00.5-1.8 2.4 2.4 0 00-.4-1.5 1.4 1.4 0 00-1.1-.6 1.3 1.3 0 00-1.1.5 3.2 3.2 0 00-.6 1.4l-.1 1.1a5.5 5.5 0 01-1.3 3 3.1 3.1 0 01-2.4.8 3.6 3.6 0 01-1.6-.4 3.6 3.6 0 01-1.2-1 4.5 4.5 0 01-.8-1.5 6.4 6.4 0 01-.2-2z' />
      <path d='M176 119a6 6 0 011-2 4.8 4.8 0 011.8-1.4 6 6 0 012.2-.5 9.2 9.2 0 015.2 1.4 5.7 5.7 0 011.6 1.6 4.9 4.9 0 01.8 2 6 6 0 01-.2 2.4 5.5 5.5 0 01-1.2 2.2 4.6 4.6 0 01-1.7 1.3 5.8 5.8 0 01-2.1.5 8 8 0 01-2.4-.4l-1-.2 2.3-8.1-.3-.2a3.3 3.3 0 00-2.5.2 3.2 3.2 0 00-1.5 2 3.6 3.6 0 000 2 4.4 4.4 0 00.9 1.7l-1.9 1a4.8 4.8 0 01-1.2-2.4 6.3 6.3 0 01.2-3zm10.4 3a3 3 0 00.1-1.1 2.7 2.7 0 00-.3-1.1 2.9 2.9 0 00-.9-.9 3.7 3.7 0 00-1.2-.6h-.1l-1.6 5.5.3.1a3.4 3.4 0 002.4 0 2.5 2.5 0 001.3-1.9z' />
      <path d='M171.8 131.3a2.7 2.7 0 011.4-1.6 2.7 2.7 0 012.1.2l6.8 3 .8-1.7 2 .9-.5 1a1 1 0 000 .9 1.4 1.4 0 00.7.6l2 .9-1 2-3.1-1.3-1 2.3-2-.8 1.1-2.4-7.4-3.3-1 2.2-1.9-.9z' />
      <path d='M162.8 145.7a6 6 0 014.9-2.5l.1 2.2a5.3 5.3 0 00-2 .4 4 4 0 00-1.5 1.3 2.7 2.7 0 00-.7 1.6 1.5 1.5 0 00.6 1.2 1.4 1.4 0 001 .3 2.2 2.2 0 001.2-.8l.9-.8a5.7 5.7 0 012.7-1.6 3 3 0 012.7.7 3.7 3.7 0 011 1.3 3.5 3.5 0 01.3 1.4 4.5 4.5 0 01-.3 1.6 7 7 0 01-3 3.5 6.3 6.3 0 01-2.3.5l-.2-2.1a4.2 4.2 0 001.6-.4 3.7 3.7 0 001.4-1.1 2.4 2.4 0 00.6-1.5 1.4 1.4 0 00-.6-1.1 1.2 1.2 0 00-1-.3 3.3 3.3 0 00-1.4.8l-.8.8a5.4 5.4 0 01-2.8 1.6 3.1 3.1 0 01-2.4-.7 3.9 3.9 0 01-1-1.3 3.5 3.5 0 01-.4-1.6 4.5 4.5 0 01.3-1.7 6.7 6.7 0 011-1.7z' />
      <path d='M165.8 161.6l-2 1.8-1.3-1.5-.1.1a3.3 3.3 0 01.2 2.2 3.8 3.8 0 01-1 1.9 4.3 4.3 0 01-3.9 1.3 9.4 9.4 0 01-6.5-6.9 4.3 4.3 0 011.4-3.7 3.8 3.8 0 012-1 3.4 3.4 0 012.2.3l-4.6-5 1.8-1.7zm-11-3.8a2.8 2.8 0 00-1 2.2 3.2 3.2 0 001 2.2l1.4 1.5a3.2 3.2 0 002.2 1.1 2.8 2.8 0 002.1-.9 3.3 3.3 0 001-1.8 1.8 1.8 0 00-.4-1.6l-3-3a1.8 1.8 0 00-1.6-.6 3.3 3.3 0 00-1.8 1z' />
      <path d='M141.7 165.1a6 6 0 012.2-.9 4.8 4.8 0 012.2.2 5.7 5.7 0 012 1 8.6 8.6 0 011.8 2 8.1 8.1 0 011 2.5 5.6 5.6 0 01.2 2.3 4.8 4.8 0 01-.8 2 6 6 0 01-1.7 1.7 5.5 5.5 0 01-2.3.8 4.6 4.6 0 01-2.1-.1 5.6 5.6 0 01-2-1.1 7.8 7.8 0 01-1.5-1.8l-.6-.9 7.1-4.5-.2-.3a3.3 3.3 0 00-2-1.5 3.1 3.1 0 00-2.5.5 3.5 3.5 0 00-1.3 1.4 4.3 4.3 0 00-.4 1.9l-2-.5a5 5 0 01.6-2.6 6.5 6.5 0 012.3-2zm5.8 9.2a3.2 3.2 0 001-1 2.7 2.7 0 00.4-1 3 3 0 000-1.2 3.8 3.8 0 00-.6-1.2v-.1l-4.9 3 .2.3a3.4 3.4 0 001.8 1.5 2.5 2.5 0 002.1-.3zm1.5 2.8l1.8-.2.5 4.7-2.5.3z' />
      <path d='M129.1 171.6a5.7 5.7 0 012.4-.4 4.8 4.8 0 012 .5 6 6 0 011.8 1.5 8.8 8.8 0 011.3 2.4 8.7 8.7 0 01.7 2.6 5.9 5.9 0 01-.3 2.3 4.8 4.8 0 01-1.2 1.8 5.8 5.8 0 01-2 1.3 5.2 5.2 0 01-3 .3 4.7 4.7 0 01-2.5-1.4l1.6-1.7a2.6 2.6 0 001.4 1 2.8 2.8 0 001.8-.2 2.6 2.6 0 001.6-1.6 3.5 3.5 0 00-.2-2.4l-.8-2a3.5 3.5 0 00-1.5-1.9 2.6 2.6 0 00-2.3 0 2.8 2.8 0 00-1.5 1.2 3.8 3.8 0 00-.4 1.8l-2.2-.3a5 5 0 01.8-2.8 5.3 5.3 0 012.5-2z' />
      <path d='M124.5 186.5l-2.4.7-3.4-11.8 2.5-.7zm-.6 2.5a1.6 1.6 0 011.2 0 1.3 1.3 0 01.7.9v.4a1.4 1.4 0 010 1 1.6 1.6 0 01-1 .6 1.5 1.5 0 01-1.2 0 1.3 1.3 0 01-.7-.8v-.4a1.4 1.4 0 010-1 1.6 1.6 0 011-.7z' />
      <path d='M105 177.7a2 2 0 011.7.4 2.6 2.6 0 01.8 1.4h.1a3 3 0 011.1-2 4.2 4.2 0 012.2-.8 4 4 0 013 .6 3.5 3.5 0 011.4 2.5 3.1 3.1 0 01-1 3 7 7 0 01-3.8 1.3l-2.2.3.1 1a2.4 2.4 0 00.8 1.7 2.6 2.6 0 002 .4 3.1 3.1 0 001.7-.7 4.3 4.3 0 001-1.4l1.7 1.2a4.8 4.8 0 01-1.5 2 5.5 5.5 0 01-2.7 1 5.4 5.4 0 01-3.8-.7 4 4 0 01-1.7-3l-.8-6.1-1.3.1-.2-2zm5.4 1a3.5 3.5 0 00-1.9.8 1.6 1.6 0 00-.6 1.5l.2 1.8 2.2-.3c1.8-.2 2.6-.9 2.4-2v-.4a1.5 1.5 0 00-.7-1.2 2.6 2.6 0 00-1.6-.2z' />
      <path d='M97.3 177.9a2.6 2.6 0 012 .7 2.6 2.6 0 01.5 1.9l-.3 14.9h-2.6l.4-15.4h-1.7v-2.2z' />
      <path d='M88.9 191.9a1.5 1.5 0 011 .5 1.3 1.3 0 01.3 1v.4a1.4 1.4 0 01-.5 1 1.6 1.6 0 01-1.2.1 1.5 1.5 0 01-1-.5 1.4 1.4 0 01-.3-1v-.4a1.4 1.4 0 01.5-.9 1.6 1.6 0 011.2-.2zm1.5-2l-2.5-.4 1.5-12.2 2.6.4z' />
      <path d='M80.9 175.6a6.4 6.4 0 012.7 1.3 6.5 6.5 0 011.6 2.1l-2 1.1a5.4 5.4 0 00-1.2-1.6 4.1 4.1 0 00-1.8-.9 2.7 2.7 0 00-1.7 0 1.4 1.4 0 00-.8 1 1.4 1.4 0 00.1 1.2 2.2 2.2 0 001.2.8l1 .4a5.9 5.9 0 012.7 1.7 3 3 0 01.5 2.8 3.6 3.6 0 01-.7 1.4 3.2 3.2 0 01-1.2 1 4 4 0 01-1.5.4 5.8 5.8 0 01-1.9-.2 6.1 6.1 0 01-2.5-1.1 6.4 6.4 0 01-1.5-1.8l2-1.2a4.2 4.2 0 001 1.3 3.6 3.6 0 001.5.8 2.4 2.4 0 001.7 0 1.4 1.4 0 00.7-1 1.3 1.3 0 00-.2-1.2 3.3 3.3 0 00-1.3-.8l-1-.4a5.5 5.5 0 01-2.7-1.9 3.2 3.2 0 01-.3-2.5 3.7 3.7 0 01.7-1.5 3.6 3.6 0 011.2-1 4.4 4.4 0 011.7-.4 6.8 6.8 0 012 .2z' />
      <path d='M69.2 172.3a2.4 2.4 0 011.5 3.5l-2.7 7 1.7.7-.7 2-1-.5a1.1 1.1 0 00-1 0 1.4 1.4 0 00-.5.7l-.8 2-2.1-.8 1.2-3.2-2.4-1 .8-2 2.4 1 3-7.4-2.3-1 .8-2z' />
      <path d='M59.3 167.3a5.9 5.9 0 011.8 1.5 4.9 4.9 0 01.9 2 5.7 5.7 0 010 2.3 9.3 9.3 0 01-2.6 4.6 5.7 5.7 0 01-2 1.2 5 5 0 01-2.2.3 6 6 0 01-2.3-.8 5.7 5.7 0 01-1.8-1.6 4.8 4.8 0 01-.9-2 5.9 5.9 0 01.1-2.2 8.2 8.2 0 011-2.2l.4-.9 7.3 4.2.2-.3a3.3 3.3 0 00.4-2.5A3.1 3.1 0 0058 169a3.7 3.7 0 00-2-.5 4.4 4.4 0 00-1.7.5l-.6-2a4.9 4.9 0 012.6-.6 6.5 6.5 0 013 .8zm-5.4 9.3a3 3 0 001.2.5 2.6 2.6 0 001.1-.2 2.8 2.8 0 001-.5 4 4 0 00.8-1l.1-.2-5-2.8-.1.2a3.4 3.4 0 00-.5 2.3 2.5 2.5 0 001.4 1.8z' />
      <path d='M48.3 159.7a6.2 6.2 0 011.9 2.3 6.3 6.3 0 01.5 2.6l-2.2.2a5.3 5.3 0 00-.4-2 4.1 4.1 0 00-1.2-1.5 2.8 2.8 0 00-1.6-.7 1.5 1.5 0 00-1.2.6 1.4 1.4 0 00-.3 1 2.2 2.2 0 00.7 1.3l.8.8A5.8 5.8 0 0147 167a3 3 0 01-.7 2.7 3.6 3.6 0 01-1.3 1 3.3 3.3 0 01-1.5.3 4 4 0 01-1.5-.3 6 6 0 01-1.7-1 5.5 5.5 0 01-2.4-4.3l2.2-.2a4.4 4.4 0 00.4 1.5 3.7 3.7 0 001.1 1.4 2.5 2.5 0 001.5.7 1.4 1.4 0 001.1-.6 1.3 1.3 0 00.3-1 3 3 0 00-.8-1.4l-.8-.8a5.4 5.4 0 01-1.6-2.8 3.1 3.1 0 01.7-2.4 3.6 3.6 0 011.3-1 3.5 3.5 0 011.5-.4 4.6 4.6 0 011.7.3 6.9 6.9 0 011.8 1z' />
      <path d='M31.3 158A88.5 88.5 0 018.2 98.3a90 90 0 011-13.8l2.2.3a88.2 88.2 0 00-1 13.5A86.3 86.3 0 0033 156.5z' />
    </RoundContainer>

    <path d='M122.7 120.5H70.9L78.8 85h51.7zm-49-2.2h47.2l7-31.2H80.4z' />
    <path d='M73 120.5H71V74.2h24.6v5h26v7.3h-2.3v-5h-26v-5H73.2v44z' />
    <path d='M100.5 95.1h2.2v15.2h-2.2z' />
    <path d='M94 101.6h15.2v2.2H94z' />
  </svg>
);

export default StampSpecialists;
